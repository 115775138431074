import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117'),
	() => import('./nodes/118'),
	() => import('./nodes/119'),
	() => import('./nodes/120'),
	() => import('./nodes/121'),
	() => import('./nodes/122'),
	() => import('./nodes/123'),
	() => import('./nodes/124'),
	() => import('./nodes/125'),
	() => import('./nodes/126'),
	() => import('./nodes/127'),
	() => import('./nodes/128'),
	() => import('./nodes/129'),
	() => import('./nodes/130'),
	() => import('./nodes/131'),
	() => import('./nodes/132'),
	() => import('./nodes/133'),
	() => import('./nodes/134'),
	() => import('./nodes/135'),
	() => import('./nodes/136'),
	() => import('./nodes/137'),
	() => import('./nodes/138'),
	() => import('./nodes/139'),
	() => import('./nodes/140'),
	() => import('./nodes/141'),
	() => import('./nodes/142'),
	() => import('./nodes/143'),
	() => import('./nodes/144'),
	() => import('./nodes/145'),
	() => import('./nodes/146'),
	() => import('./nodes/147'),
	() => import('./nodes/148'),
	() => import('./nodes/149'),
	() => import('./nodes/150'),
	() => import('./nodes/151'),
	() => import('./nodes/152'),
	() => import('./nodes/153'),
	() => import('./nodes/154'),
	() => import('./nodes/155'),
	() => import('./nodes/156'),
	() => import('./nodes/157'),
	() => import('./nodes/158'),
	() => import('./nodes/159'),
	() => import('./nodes/160'),
	() => import('./nodes/161'),
	() => import('./nodes/162'),
	() => import('./nodes/163'),
	() => import('./nodes/164'),
	() => import('./nodes/165'),
	() => import('./nodes/166'),
	() => import('./nodes/167'),
	() => import('./nodes/168'),
	() => import('./nodes/169'),
	() => import('./nodes/170'),
	() => import('./nodes/171')
];

export const server_loads = [0,2,3,7];

export const dictionary = {
		"/": [23],
		"/(protected)/admin/(pages)/bank-accounts": [27,[2,3,4]],
		"/(protected)/admin/(pages)/businesses": [28,[2,3,4]],
		"/(protected)/admin/(forms)/businesses/new": [24,[2,3]],
		"/(protected)/admin/(pages)/dialogues": [29,[2,3,4]],
		"/(protected)/admin/(pages)/enterprises": [30,[2,3,4]],
		"/(protected)/admin/(forms)/enterprises/new": [25,[2,3]],
		"/(protected)/admin/(pages)/leads": [31,[2,3,4]],
		"/(protected)/admin/(pages)/printers": [32,[2,3,4]],
		"/(protected)/admin/(pages)/readers": [33,[2,3,4]],
		"/(protected)/admin/(pages)/users": [34,[2,3,4]],
		"/(protected)/admin/(forms)/users/new": [26,[2,3]],
		"/api/token/[token]": [~171],
		"/(unprotected)/challenge/[id]": [~160],
		"/(unprotected)/changelog": [161],
		"/(protected)/dashboard": [35,[2]],
		"/(unprotected)/error": [162],
		"/(protected)/finance/(pages)": [52,[2,5,6]],
		"/(protected)/finance/(pages)/accounts": [53,[2,5,6]],
		"/(protected)/finance/(forms)/add-funds": [36,[2,5]],
		"/(protected)/finance/(forms)/add-funds/debit": [37,[2,5]],
		"/(protected)/finance/(forms)/add-funds/transfer": [38,[2,5]],
		"/(protected)/finance/(forms)/agreements": [39,[2,5]],
		"/(protected)/finance/(pages)/all-cards": [54,[2,5,6]],
		"/(protected)/finance/(forms)/all-cards/new": [40,[2,5]],
		"/(protected)/finance/(pages)/bills": [55,[2,5,6]],
		"/(protected)/finance/(forms)/create-demand": [41,[2,5]],
		"/(protected)/finance/(forms)/create-penalty": [42,[2,5]],
		"/(protected)/finance/(pages)/expenses": [56,[2,5,6]],
		"/(protected)/finance/(pages)/integrations": [57,[2,5,6]],
		"/(protected)/finance/(forms)/link-account": [43,[2,5]],
		"/(protected)/finance/(forms)/make-payment": [44,[2,5]],
		"/(protected)/finance/(pages)/my-cards": [58,[2,5,6]],
		"/(protected)/finance/(forms)/onboard": [45,[2,5]],
		"/(protected)/finance/(pages)/partners": [59,[2,5,6]],
		"/(protected)/finance/(forms)/pay-bill": [46,[2,5]],
		"/(protected)/finance/(forms)/pay-demand/[id]": [47,[2,5]],
		"/(protected)/finance/(pages)/payment-requests": [60,[2,5,6]],
		"/(protected)/finance/(forms)/pending": [48,[2,5]],
		"/(protected)/finance/(pages)/recipients": [61,[2,5,6]],
		"/(protected)/finance/(forms)/recipients/new": [49,[2,5]],
		"/(protected)/finance/(forms)/rfis": [50,[2,5]],
		"/(protected)/finance/(pages)/spend-limits": [62,[2,5,6]],
		"/(protected)/finance/(forms)/spend-limits/new": [51,[2,5]],
		"/(protected)/food-and-beverages/(pages)/availability": [63,[2,7]],
		"/(protected)/food-and-beverages/(pages)/discounts": [64,[2,7]],
		"/(protected)/food-and-beverages/(pages)/insights": [65,[2,7]],
		"/(protected)/food-and-beverages/(pages)/menus": [66,[2,7]],
		"/(protected)/food-and-beverages/(pages)/menus/[id]/active-instances": [67,[2,7,8]],
		"/(protected)/food-and-beverages/(pages)/menus/[id]/edit": [68,[2,7,8]],
		"/(protected)/food-and-beverages/(pages)/menus/[id]/publish": [69,[2,7,8]],
		"/(protected)/food-and-beverages/(pages)/modifier-groups": [70,[2,7]],
		"/(protected)/food-and-beverages/(pages)/modifier-options": [71,[2,7]],
		"/(protected)/food-and-beverages/(pages)/products": [72,[2,7]],
		"/(unprotected)/forgot-password": [163],
		"/(protected)/guests/(pages)/checks": [73,[2,9]],
		"/(protected)/guests/(pages)/customers": [74,[2,9]],
		"/(protected)/guests/(pages)/insights": [75,[2,9]],
		"/(protected)/guests/(pages)/payments": [76,[2,9]],
		"/(protected)/help/[id]": [~77,[2]],
		"/(protected)/inventory/(pages)/allergens": [78,[2,10]],
		"/(protected)/inventory/(pages)/counts/past": [80,[2,10,11]],
		"/(protected)/inventory/(pages)/counts/past/[id]": [81,[2,10,11]],
		"/(protected)/inventory/(pages)/counts/past/[id]/variance/[itemId]": [82,[2,10,11]],
		"/(protected)/inventory/(pages)/counts/recurring": [83,[2,10,11]],
		"/(protected)/inventory/(pages)/counts/upcoming": [84,[2,10,11]],
		"/(protected)/inventory/(pages)/counts/[id]": [79,[2,10,11]],
		"/(protected)/inventory/(pages)/groups": [85,[2,10]],
		"/(protected)/inventory/(pages)/groups/[id]": [86,[2,10]],
		"/(protected)/inventory/(pages)/invoices/new": [88,[2,10]],
		"/(protected)/inventory/(pages)/invoices/[id]": [87,[2,10]],
		"/(protected)/inventory/(pages)/items": [89,[2,10]],
		"/(protected)/inventory/(pages)/items/[id]": [90,[2,10]],
		"/(protected)/inventory/(pages)/locations": [91,[2,10]],
		"/(protected)/inventory/(pages)/measurements/conversions": [92,[2,10]],
		"/(protected)/inventory/(pages)/measurements/table": [93,[2,10]],
		"/(protected)/inventory/(pages)/recipes/prep-items": [94,[2,10]],
		"/(protected)/inventory/(pages)/recipes/prep-items/[id]": [95,[2,10]],
		"/(protected)/inventory/(pages)/vendors": [96,[2,10]],
		"/(protected)/inventory/(pages)/vendors/[id]": [97,[2,10]],
		"/(unprotected)/join/[id]": [164],
		"/(unprotected)/login": [165],
		"/(protected)/overview/(pages)": [102,[2,12]],
		"/(protected)/overview/(pages)/budget-actuals": [104,[2,12]],
		"/(protected)/overview/(pages)/budget": [103,[2,12]],
		"/(protected)/overview/(forms)/budget/create-incidental-cost": [99,[2]],
		"/(protected)/overview/(forms)/budget/create-recurring-cost": [100,[2]],
		"/(protected)/overview/(forms)/budget/[id]": [98,[2]],
		"/(protected)/overview/(pages)/daily-summary": [105,[2,12]],
		"/(protected)/overview/(forms)/dialogue/[id]": [101,[2]],
		"/(protected)/people/(new)/dailies": [106,[2,13]],
		"/(protected)/people/(new)/departments": [107,[2,13]],
		"/(protected)/people/(new)/insights": [108,[2,13]],
		"/(protected)/people/(new)/payroll": [109,[2,13]],
		"/(protected)/people/(new)/payroll/[startDate]/[endDate]": [110,[2,13]],
		"/(protected)/people/(new)/payroll/[startDate]/[endDate]/[staffId]": [111,[2,13]],
		"/(protected)/people/(new)/roles": [112,[2,13]],
		"/(protected)/people/(new)/team": [113,[2,13]],
		"/(protected)/profile/address": [114,[2,15]],
		"/(protected)/profile/general": [115,[2,15]],
		"/(protected)/profile/login-history": [116,[2,15]],
		"/(protected)/profile/security": [117,[2,15]],
		"/(protected)/reporting": [118,[2,16]],
		"/(protected)/reporting/bank-deposits": [119,[2,16]],
		"/(protected)/reporting/daily-summary": [~120,[2,16]],
		"/(protected)/reporting/daily-summary/[start]/[comparison]": [~121,[2,16]],
		"/(protected)/reporting/eod": [122,[2,16]],
		"/(protected)/reporting/gift-cards": [123,[2,16]],
		"/(protected)/reporting/gift-cards/balances": [124,[2,16]],
		"/(protected)/reporting/gift-cards/history": [125,[2,16]],
		"/(protected)/reporting/gift-cards/transactions": [126,[2,16]],
		"/(protected)/reporting/reports": [127,[2,16]],
		"/(protected)/reservations/general": [128,[2,17]],
		"/(protected)/reservations/notifications": [129,[2,17]],
		"/(protected)/reservations/online": [130,[2,17]],
		"/(protected)/reservations/reporting": [131,[2,17]],
		"/(protected)/reservations/service-periods": [132,[2,17]],
		"/(unprotected)/reset-password/[token]": [166],
		"/(protected)/scheduling/(pages)/labour-targets": [134,[2,18]],
		"/(protected)/scheduling/(pages)/logs/[date]": [135,[2,18]],
		"/(protected)/scheduling/(pages)/requests": [136,[2,18]],
		"/(protected)/scheduling/(forms)/schedule/[date]": [133,[2]],
		"/(protected)/scheduling/(pages)/schedule/[startDate]/[endDate]": [137,[2,18]],
		"/(protected)/scheduling/(pages)/settings": [138,[2,18]],
		"/(unprotected)/select": [~167,[21]],
		"/(unprotected)/select/business": [~168,[21]],
		"/(protected)/settings/(pages)": [140,[2,19]],
		"/(protected)/settings/(pages)/account": [141,[2,19]],
		"/(protected)/settings/(pages)/general-service": [143,[2,19]],
		"/(protected)/settings/(pages)/general": [142,[2,19]],
		"/(protected)/settings/(pages)/kyb": [144,[2,19]],
		"/(protected)/settings/(forms)/kyb/update": [139,[2]],
		"/(protected)/settings/(pages)/online-ordering": [145,[2,19]],
		"/(protected)/settings/(pages)/pos": [146,[2,19]],
		"/(protected)/settings/(pages)/printing": [147,[2,19]],
		"/(unprotected)/sign-up": [169,[22]],
		"/(protected)/store-setup/(pages)/comp-reasons": [148,[2,20]],
		"/(protected)/store-setup/(pages)/floor-plans": [149,[2,20]],
		"/(protected)/store-setup/(pages)/kds": [150,[2,20]],
		"/(protected)/store-setup/(pages)/order-kitchen-display": [~151,[2,20]],
		"/(protected)/store-setup/(pages)/printers": [152,[2,20]],
		"/(protected)/store-setup/(pages)/service-areas": [153,[2,20]],
		"/(protected)/store-setup/(pages)/service-fees": [154,[2,20]],
		"/(protected)/store-setup/(pages)/table-management": [155,[2,20]],
		"/(protected)/store-setup/(pages)/taxes": [156,[2,20]],
		"/(protected)/store-setup/(pages)/taxes/[id]": [157,[2,20]],
		"/(protected)/store-setup/(pages)/tip-outs": [158,[2,20]],
		"/(protected)/store-setup/(pages)/void-reasons": [159,[2,20]],
		"/(unprotected)/stripe-reader/register": [170]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';